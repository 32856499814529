@import "bootstrap/dist/css/bootstrap.min.css";

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.formContainer .formBody .form-control,
.formContainer .formBody .ant-input {
    border: 1px solid rgba(0, 0, 0, 0.2705882353);
    background-color: white !important;
    outline: none !important;
    margin-top: 10px !important;

}

.formContainer {
    margin: 0px 10px 0 10px !important;
    padding-top: 10px !important;
}

.form-control {
    border-radius: 0px !important;
}

.actionContainer .anticon {
    padding: 10px;
}

.os-viewport {
    overflow-x: hidden !important;
}

//common
h4 {
    font-size: 24px !important;
}

.formContainer {
    position: relative;
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 5px;
    border-top: 1px solid #80808038;
    display: flex;
    justify-content: flex-end;
    background-color: white;

    .ant-btn {
        margin-right: 5px;
        width: auto;
        padding: 0 15px;
    }
}

.cancelButton {
    border-radius: 0px;
    border: solid 1px red;
}

.ant-select-selector {
    width: 100% !important;
    height: 40px !important;
    border-radius: 0px !important;
    padding: 0 11px !important;
}

.fullScreen {
    position: fixed !important;
    right: 0 !important;
    width: 50% !important;

}

.heading {
    font-size: 28px !important;
    margin-top: 20px !important;
    margin-bottom: 10px !important;
}

.ant-input,
.ant-picker {
    border-radius: 0px !important;
}

.css-cppmpw-MuiDivider-root {
    align-items: flex-start !important;
}

.css-1vm0r4s-MuiPaper-root {
    border-radius: 0px !important;
    border: 1px solid lightgray !important;
}


//policy create upload file button 


//james code
.file-container {
    padding: 1em;

    .file-upload {
        position: relative !important;

        input[type=file]::file-selector-button {
            margin-right: 20px;
            border: none;
            background: #1976d2;
            padding: 10px 20px;
            border-radius: 0px;
            color: #fff;
            cursor: pointer;
            font-weight: 600 !important;
        }

        input[type=file]::file-selector-button:hover {
            background-color: #0d45a5;
        }
    }
}

//report page 

.reportAllbutton {
    width: 200px !important;
    border-radius: 0px !important;
    font-weight: 600 !important;
    color: white !important;
    height: 40px !important;
    background-color: #29abe2 !important;
}

.option {
    border-radius: 0px !important;
}

.buttons {
    border-radius: 0px !important;
    width: 200px !important;
    border: 1px solid gray !important;
}

.com-contact-table {
    margin-bottom: 28px !important;
}

.drawer-dropDown {
    padding-top: 64px !important;
}

//searchSymbol
.searchSymbol {
    border: 1px solid #D9D9D9;
    padding: 9px 9px 0px 9px !important;
}

.searchSymbolReport {
    border: 1px solid #D9D9D9;
    height: 40px !important;
    padding: 10px 10px 0 10px !important;

}

.searchSymbolWallet {
    border: 1px solid #D9D9D9;
    height: 40px !important;
    padding: 14px 10px 0 10px !important;

}

//addPageDrawerAlignment
.dropDownContainer {
    margin-top: 8px !important;
}


//sidebar icon and letters
.css-i4bv87-MuiSvgIcon-root,
.css-10hburv-MuiTypography-root {
    color: black !important;
}

// dashboard nav profile logo
.profile {
    text-transform: capitalize !important;
}

//profile page input box
.ant-input-affix-wrapper {
    border-radius: 0px !important;
}

.ProfileContainer {
    padding: 20px 20px 0 20px !important;
}

.ant-drawer .ant-drawer-content-wrapper {
    box-shadow: none !important;
}

#alert-dialog-description {
    width: 280px !important;
}

.popupTable {

    table,
    th,
    td {
        text-align: center !important;
        border: 1px solid black;
        padding: 1px 5px !important;
        position: relative !important;
    }
}
.AntdTableContainer {
    height: 44px !important;
    overflow: scroll !important;
}    

.AntdTableContainer::-webkit-scrollbar {
    display: none !important;
}     

//approved page popup table size

.ant-input-affix-wrapper-disabled {
    background-color: white !important;

}

.suffixInput .ant-input {
    border: none !important;
}

.csvButton {
    border-radius: 0px !important;
    height: 40px !important;

    .csv {
        text-decoration: none !important;

    }
}

.urlLink {
    text-decoration: underline !important;
    cursor: pointer !important;
    color: #0e12f0 !important;
}

//scroll bar
body::-webkit-scrollbar {
    display: none !important;
}

.MuiDrawer-paper::-webkit-scrollbar {
    width: 0px !important;
}

#alert-dialog-description {
    width: 100% !important;
}


@media screen and (max-width: 768px) {
    .css-2dum1v-MuiDrawer-docked .MuiDrawer-paper {
        display: none !important;
    }

    .renderingContainer {
        height: calc(100% - 65px);
        padding-top: 80px !important;
        // padding-bottom: 15px;
        padding-right: 10px !important;
        padding-left: 10px !important;
        scroll-behavior: auto !important;
    }

}

//loader
.loader {
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(244,244,244);
    padding: 10px 40px;
}

.loader-text {
    font-weight: 600;
}

.spinner-grow {
    width: 0.5rem !important;
    height: 0.5rem !important;
    margin: 2px;
}
.form-control:focus, .ant-input:focus, .ant-input:hover {
    outline: none;
    border-color: unset;
    outline: 0;
    box-shadow: none !important;
    border: 1px solid #00000045;
}