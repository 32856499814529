.sideBar {
    z-index: 1200;
    background: white;
    position: fixed;
    height: 100%;   
}


@mixin transition {
    transition-duration: .2s;
    -webkit-transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@media screen and (min-width: 768px) {
    .sideBarContainerClosed {
        width: 60px;
        @include transition;

        .customIcon {
            padding: 14px !important;
            font-size: 23px !important;
            margin-right: 5px;      
        }

        .parentNavLink .fa {
            font-size: 24px !important;
            margin-bottom: 6px;
        }
    }

    .mainContainerClosed {
        width: calc(100% - 49px);
        margin-left: 49px;
    }

    .sideBarContainerOpened {
        width: 240px;
        @include transition;
    }

    .mainContainerOpened {
        width: calc(100% - 240px);
        margin-left: 240px;
        @include transition;
    }
}

@media screen and (max-width: 230px) {
    .mainContainer {
        position: fixed;
        height: 100%;
    }
}

@media screen and (max-width: 768px) {
    .sideBar {
        top: 0;
        height: 100%;
        position: fixed;
    }

    .mainContainer {
        margin-left: 0;
        height: 100%;
    }

    .sideBarContainerClosed {
        width: 0;
        max-width: 0;
        overflow: hidden;
        @include transition;
    }

    .mainContainerClosed {
        transition: 0s;
        width: 100%;
    }

    .sideBarContainerOpened {
        width: 240px;
        @include transition;
    }

    .mainContainerOpened {
        transition: 0s;
        width: 100%;
    }
    
}

.renderingContainer {
    height: calc(100% - 65px);
    padding-top: 90px;
    padding-right: 25px;
    padding-left: 44px;
    scroll-behavior: auto !important;
}