
marquee {
    font-weight: 400 !important;
    font-family: 'Times New Roman', Times, serif !important;
}

.marquee {
    border: 1px solid #29abe2;
}

.flip-card {
    padding: 0 !important;
    flex-grow: 1 !important;
    margin-top: 20px !important
}
.bigbox{
    display: flex !important;
    gap: 60px;
}
.bigbox:hover .cards{
    transform: translateX(120%);
    
}

.card {
    position: relative;
    width: 300px !important;
    height: 310px;
    background: linear-gradient(180deg, #29abe2 84%, #4144B2 16%);
    transform-style: preserve-3d;
    transform: perspective(1000px);
    transition: 1s;
    border-radius: 0px !important;
    border:none !important;
}

.card .details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    h1 {
        text-align: center !important;
        padding: 10px;
        color: white;
        font-size: 22px !important;
        font-weight: 400 !important;
        background-color: #4144B2 !important;

    }

    .policy-content {
        text-align: start !important;
        color: white !important;
        margin-top: 30px !important;

        h2 {
            font-size: 16px !important;
            padding-left: 10px !important;
            font-weight: 400 !important;
        }
    }
}

.card .details .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.card .imgBox {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    transform-origin: left;
    transform-style: preserve-3d;
    background: linear-gradient(180deg, #29abe2 84%, #4144B2 16%);
    transition: 1s;
    border-radius: 0px !important;
    color: white !important;
}

.card:hover .imgBox {
    transform: rotateY(-180deg);
}

.card:hover {
    transform: translateX(100%);
}

.first {
    backface-visibility: hidden !important;
    display: flex;
    flex-direction: column !important;
    text-align: center;
    align-items: center !important;
    height: 300px !important;
    box-shadow: 0 -15px 15px rgba(250, 246, 246, 0.05),
    inset 0 -15px 15px rgba(255, 255, 255, 0.05),
    0 15px 15px rgba(255, 255, 255, 0.5),
    inset 0 15px 15px rgba(255, 255, 255, 0.5) !important;

    h1 {
        padding: 12px !important;
        color: white !important;
        font-weight: 400 !important;
        font-size: 22px !important;
        background-color: #4144B2 !important;
        width: 100% !important;
    }
}

.card:hover .first {
    display: none !important;
}

.Second {
    transform: rotateY(0deg) !important;
    display: none;
    color: #413F3D !important;
    text-align: center;
    transition: 5s !important;
    height: 290px !important;
    

    h1 {
        padding: 10px !important;
        background-color: #4144B2 !important;
        color: white;
        font-size: 22px !important;
        font-weight: 400 !important;
    }

    .policy-content {
        text-align: start !important;
        color: white !important;
        background-color: #29abe2;
        margin: 10px 30px 0px 30px !important;
        height: 190px !important;
        overflow-y: auto !important;
        padding: 10px;


        h2 {
            font-size: 16px !important;
            padding-left: 10px !important;
            font-weight: 400 !important;
        }
    }
}

.card:hover .Second {
    transform: rotateY(180deg) !important;
    display: block !important;
    backface-visibility: hidden !important;
}

.center-content {
    display: flex !important;
    justify-content: space-between !important;
}

.premium h2,
.count h2 {
    display: flex !important;
    flex-direction: column !important;
    color: white !important;
    font-weight: 400 !important;
    font-size: 17px !important;

}

.premium h2 {
    padding-right: 20px !important;
}

.count h2 {
    padding-left: 20px !important;
}

.premium,
.count {
    margin-top: 40px !important;
}

.view {
    position: fixed !important;
    bottom: -8px !important;
}
.details .policy-content {
    text-align: start !important;
    color: white !important;
    background-color: #29abe2;
    margin: 10px 30px 10px 30px !important;
    height: 180px !important;
    overflow-y: auto !important;
    padding: 10px;


    h2 {
       
        font-size: 16px !important;
        padding-left: 10px !important;
        font-weight: 400 !important;
    }
}
.wrapper{
    
    height: 310px;
    width: 300px;
    position: relative;
    cursor: default;
  }
  .wrapper .display,
  .wrapper span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .wrapper .display{
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    z-index: 999;
    height: 310px;
    border-radius: 50%;
    transition: .2s ease-in !important;
    width: 300px;
    background: linear-gradient(to bottom right,#4144B2 30% , #29abe2 80% ) !important;
    text-align: center;
    box-shadow: 0 -15px 15px rgba(255,255,255,0.05),
                inset 0 -15px 15px rgba(255,255,255,0.05),
                0 15px 15px rgba(0,0,0,0.5),
                inset 0 15px 15px rgba(0,0,0,0.5) !important;
  }
 
  .display #time{
    line-height: 85px;
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 1px;
    background-color: white !important;
    -webkit-text-fill-color: transparent;
    animation: animate 1.5s linear infinite;
  }
  .day{
    font-size: 34px;
    color: white;
    font-family:Verdana, Geneva, Tahoma, sans-serif !important;
  }
  @keyframes animate {
    100%{
      filter: hue-rotate(360deg);
    }
  }
  .wrapper span{
    height: 100%;
    width: 100%;
    background: inherit;
  }
  .wrapper span:first-child{
    filter: blur(7px);
  }
  .wrapper span:last-child{
    filter: blur(20px);
  }