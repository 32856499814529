.button{
  color: #29abe2;
  border: 1px solid #29abe2;
  border-radius: 0px !important;
}
.button:hover{
  background-color: #29abe2;
  color: white !important;
}
/* sidebar */
.css-h4y409-MuiList-root{
padding-bottom: 0px !important;
}