* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.LoginPage {
    width: 100%;
    height: 100%;   
}
.inventoryLoginContainer {
    background: linear-gradient(90deg,white 64%,skyblue 36%) !important;
    .welcome {
        font-size: 20px;
        margin-bottom: 20px;
        color: #58595d;
        font-family: Arial, Helvetica, sans-serif;
    }
    img {
        margin-bottom: 40px;
    }
    .message {
        color: #58595d;
        font-size: 16px !important;
        font-family: sans-serif;
        font-weight: 100;
    }
    .loginLeft {
        text-align: center;
        justify-content: center;
        height: 100vh;
        display: flex;
        flex-direction: column;
        padding: 60px ;
    }
    .input input {
        width: 90%;
        padding: 8px 10px;
        margin-top: 20px;
        margin-bottom: 8px;
        border-radius: 0px;
        outline: solid 1px #34ace2;
        padding-left: 20px;
        border: white;
        box-shadow: 5px 10px 8px #888888;
    }

    .remember {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        margin-left: 20px;
        font-size: 14px;
    }

    .button {
        background-color: #34ace2 !important;
        margin-top: 30px;
        border-radius: 50px;
        margin-left: 28px;
        width: 90%;
        padding: 8px;
        outline: none !important;
        border: none;
        color: white;
    }

    .forgotPassword span {
        margin-right: 22px !important;
    }
    .button:hover {
        color: #000000 !important;
    }
}

.loginRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    h1{
        text-align: end !important;
        color: white;
        font-size:36px !important;
        padding: 80px 20px 0 0 !important;
        font-weight: 800 !important;
    }
    h2{
        text-align: end !important;
        color: white;
        font-size: 20px !important;
        padding: 0 20px 0 0;
    }
}

@media screen and (max-width: 780px) {
    .loginRight {
        display: none;
        margin: 0 !important;
    }
    .loginLeft{
        padding: 10px !important;
    }

    .inventoryLoginContainer{
    background: linear-gradient(90deg,white,white) !important;
    }
  
}
@media screen and (max-width: 1000px) {
    .loginRight {
        display: none;
        margin: 0 !important;
        img{
            display: none !important;
        }
    }
  
}